import type { ButtonProps } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Link } from '@remix-run/react';
import type { FC } from 'react';

import { ArrowRight } from '~/assets/icons/arrow-right';

interface HeaderButtonProps extends ButtonProps {
  label?: string;
}

export const HeaderButton: FC<HeaderButtonProps> = ({ label, ...rest }) => (
  <Link to="/personalize">
    <ActionButton color="primaryBg" {...rest}>
      {label ?? 'Create Artwork'} <ArrowRight ml="12px" fill="white" />
    </ActionButton>
  </Link>
);

const ActionButton = styled(Button)`
  align-items: center;
  background-color: 'white';
  border-radius: 100px;
  height: 48px;
  padding: 12px 16px;

  &:hover {
    background-color: 'rgba(0,0,0,.85)';
    color: 'white';
  }
`;
