import { Flex } from '@chakra-ui/react';
import type { FC } from 'react';

import { Logo } from '~/components/Logo';

import { HeaderButton } from '../buttons/HeaderButton';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';

interface HeaderProps {
  ctaHidden?: boolean;
}

export const Header: FC<HeaderProps> = ({ ctaHidden = false }) => {
  const isLoading = useTransitionLoading();

  return (
    <Flex
      backgroundColor="primaryBg"
      as="header"
      position="fixed"
      width="100%"
      zIndex={999999999999999999}
    >
      <Flex
        maxW="1280"
        mx="auto"
        paddingInline={{ base: '16px', md: '32px' }}
        paddingBlock="16px"
        width="100%"
        justify="space-between"
        align="center"
      >
        <Logo />
        {!ctaHidden && (
          <HeaderButton isLoading={isLoading} label="Create Artwork" />
        )}
      </Flex>
    </Flex>
  );
};
