import { Divider, Flex, Image, Text } from '@chakra-ui/react';
import { Link } from '@remix-run/react';
import { safeCheckout } from '~/assets/images';
import { Column } from './Column';

const links = [
  {
    label: 'Terms & Conditions',
    url: '/terms',
  },
  {
    label: 'Privacy policy',
    url: '/privacy',
  },
  {
    label: 'Refund policy',
    url: '/refund_policy',
  },
  {
    label: 'Delivery & Payment',
    url: '/delivery',
  },
  {
    label: 'Contacts',
    url: '/contacts',
  },
];

export const Footer = () => {
  return (
    <Flex backgroundColor="primaryBg" as="footer">
      <Column
        maxW="1280"
        mx="auto"
        width="100%"
        align={{ base: 'center', md: 'start' }}
        paddingTop={{ base: '32px', md: '40px' }}
        paddingBottom={{ base: '32px', md: '48px' }}
        paddingInline="16px"
      >
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          justify="center"
          align="center"
          mb="24px"
          gap={{ base: '0px', md: '20px' }}
        >
          {links.map(link => (
            <Link to={link.url} key={link.url}>
              <Text color="whiteAlpha.600" padding="8px 0px">
                {link.label}
              </Text>
            </Link>
          ))}
        </Flex>
        <Divider mb="40px" />
        <Flex
          width={'100%'}
          align={{ base: 'center', md: 'start' }}
          justify={{ base: 'center', md: 'space-between' }}
          flexDirection={{ base: 'column-reverse', md: 'row' }}
        >
          <Text
            textAlign={{ base: 'center', md: 'start' }}
            color="whiteAlpha.400"
          >{`© ${new Date().getFullYear()} GetLunar. All rights reserved.`}</Text>
          <Image loading="lazy" src={safeCheckout} width="300px" mb="24px" />
        </Flex>
      </Column>
    </Flex>
  );
};
